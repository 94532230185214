import React, { useState } from "react"; // Import Dave McLurg's headshot image
import SimpleGallery from "./SimpleGallery";
import { BsFillSignpostSplitFill } from "react-icons/bs";
import { SlSpeedometer } from "react-icons/sl";
import { SlGlobeAlt } from "react-icons/sl";

const About = () => {
    return (
        <section className="page" style={{ background: "#c5b355" }}>
            {/* Intro */}
            <div className="full-width-black extra-padded dark center">
                {/* Begin Container */}
                <div className="container loadin-left">
                    {/* Logo */}

                    {/* Content */}
                    <div className="row padded-top">
                        <div className="col-xs-12 col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
                            <h5 style={{ fontSize: "40px" }}>PROFILE</h5>
                            <p>INSERT PARAGRAPH HERE</p>
                        </div>
                    </div>
                </div>
                {/* END Container */}
                {/* Fixed Button */}
                <a className="fixed-button-back" href="#home">
                    Back
                </a>
            </div>
            {/* END Intro */}
            {/* Icon Boxes */}
            <div className="container loadin-left-secondary" style={{ background: "#c5b355" }}>
                <div className="row padded center-mobile" style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                        <img src={require("../images/cv.png")} alt="" />
                    </div>
                </div>
            </div>
            {/* END Icon Boxes */}
            {/* Logos */}
            <div
                className="full-width extra-padded dark center"
                style={{
                    backgroundImage: `url(${require("../images/corporate/partners.jpg")})`,
                }}
            >
                <div className="container">
                    <div className="row title padded-bottom">
                        <div className="col-xs-12 col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
                            <h2>Personal Projects</h2>
                            <p>INSERT PARAGRAPH HERE</p>
                        </div>
                    </div>

                </div>
            </div>
            {/* Icon Boxes */}
            {/* END Contact */}
        </section>
    );
};

export default About;
