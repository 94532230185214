import React, { useEffect } from 'react'

const Main = () => {
    useEffect(() => {
        window.initializeRevealEffects()
        window.loadingOfFunction()
    }, [])

    return (

        <section id="hero" className="page page--current">
            <ul id="scene" className="scene" data-friction-x="0.1" data-friction-y="0.1" data-scalar-x="25" data-scalar-y="15">

                <li className="layer depth-1 dark center " data-depth="0.15">

                    {/* <img className="has-retina mx-auto" style={{ marginLeft: "auto", marginRight: "auto " }} src={require('../images/agency/logo-light.png')} alt="" width="250px" /> */}
                </li>

                <li className="layer depth-2" data-depth="0.20">
                    {/* Hero Content */}
                    <div className="hero-content dark center">

                        {/* Text */}
                        <h5>Junior Data Scientist & Entrepreneur </h5>
                        <h1><strong>Ayush Bharadwaj's Portfolio</strong></h1>

                        {/* Button */}
                        <a href="#info"><button className="button" data-modal="">Projects and CV</button></a>
                    </div>
                    {/* END Hero Content */}
                </li>

                <li className="layer depth-3 dark center" data-depth="0.15">
                    {/* Social Links */}
                    {/* Omitted for brevity */}

                    {/* Copyright */}
                    {/* <p className="small">© 2016 The Board International. All rights reserved.</p> */}
                </li>

                <li className="layer depth-4" data-depth="0.10">
                    {/* Slider */}
                    <ul className="bxslider">
                        <li style={{ backgroundImage: `url(${require('../images/agency/bg.jpeg')})` }}></li>
                        {/* Slide 1 */}
                        <li style={{ backgroundImage: `url(${require('../images/freelance/second.jpeg')})` }}></li>
                    </ul>
                    {/* END Slider */}
                </li>
            </ul>

            {/* Progress Bar */}
            <span id="progress-line"></span>

            {/* Fixed Button */}
            <a className="fixed-button" href="#info">Learn More</a>

            {/* Subscribe Modal */}
            {/* Omitted for brevity */}
        </section>


    )
}

export default Main