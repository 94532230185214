import logo from "./logo.svg";
import "./App.css";
import "./css/framework.css";
import "./css/style.css";
import "./css/skins/freelance.css";
//import "./css/skins/photoswipe/photoswipe.css";
//import "./css/skins/photoswipe/default-skin/default-skin.css";
import Main from "./components/main";
import About from "./components/About";
import LightboxModal from "./components/LightBox";
import SimpleGallery from "./components/SimpleGallery";

function App() {
  return (
    <main class="pages">
      <Main />
      <About />
    </main>
  );
}

export default App;
